<template>
  <div class="text-center text-body-2">
    <user-avatar :user="user" large/>

    <div class="mt-3 text-subtitle-1 font-weight-bold">{{ user.name }}</div>
    <div v-if="user.name !== userName">({{ user.forename }} {{ user.lastname }})</div>
    <div v-if="user.birthday" class="mt-3">{{ user.birthday | date }}</div>

    <div v-if="user.email || user.tel" class="mt-3">
      <!-- <div class="text-center text-overline mt-6">Kontakt</div> -->
      <div v-if="user.telm" class="mb-2">
        <v-icon left small>mdi-cellphone</v-icon>
        <a :href="'tel:' + user.telm">{{ user.telm | tel }}</a>
      </div>
      <div v-if="user.telp" class="mb-2">
        <v-icon left small>mdi-phone</v-icon>
        <a :href="'tel:' + user.telp">{{ user.telp | tel }}</a>
      </div>
      <div v-if="user.telw" class="mb-2">
        <v-icon left small>mdi-deskphone</v-icon>
        <a :href="'tel:' + user.telw">{{ user.telw | tel }}</a>
      </div>
      <div v-if="user.email" class="mb-2">
        <v-icon left small>mdi-at</v-icon>
        <a :href="'mailto:' + user.email">{{ user.email }}</a>
      </div>
    </div>

    <plug-address-view v-if="user.address" :item="user" class="mt-3"/>

    <div v-if="canChat" class="mt-6">
      <v-btn fab color="primary" :loading="loading" @click="openChat"><v-icon>mdi-chat</v-icon></v-btn>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/base/UserAvatar'
import PlugAddressView from '@/components/plugs/PlugAddressView'

export default {
  name: 'UserProfile',
  components: { UserAvatar, PlugAddressView },
  props: {
    user: Object
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    userName () {
      return this.user.forename + ' ' + this.user.lastname
    },
    canChat () {
      return this.$cfg.chat !== false && !!this.user.email
    }
  },
  methods: {
    async openChat () {
      this.loading = true
      // const matchingViewsByType = this.views.filter(v => v.type === view.type)
      // if (matchingViewsByType.length === 1) {
      //   const viewId = matchingViewsByType[0].id
      const viewId = 'chat' // TODO.. how to get propperly?
      if (viewId) {
        const saveChatFunc = this.$fb.fn.httpsCallable('saveChat')
        const response = await saveChatFunc({
          viewId,
          userIds: [this.user.id]
        })
        if (response.data.ok) {
          const chatId = response.data.chat.id
          this.$emit('openView', {
            id: viewId,
            itemId: chatId
          })
        }
      }
      this.loading = false
    }
  }
}
</script>
